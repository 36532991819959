//core
import { NextPage, NextPageContext } from 'next'
import ErrorPage from 'next/error'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

// local
const SignIn = dynamic(() => import('../containers/auth/SignIn'))

import { useAuthSettings } from 'hooks/useAuthSettings'
import Query from 'models/query'

type LoginProps = {
  query: Query
  cleanUrl: boolean
}

const Login: NextPage<LoginProps> = ({ query, cleanUrl }: LoginProps) => {
  const router = useRouter()

  const settings = useAuthSettings({ route: '/login', cleanUrl, query })
  if (!settings) {
    return null
  }

  // Prefetch the success page as the user will go there after the login
  router.prefetch('/success')

  const { client_id } = settings
  if (!client_id) {
    return <ErrorPage statusCode={400} />
  }
  return <SignIn />
}

export async function getServerSideProps({ query }: NextPageContext) {
  const cleanUrl = !query?.client_id
  return { props: { query, cleanUrl } }
}

export default Login
